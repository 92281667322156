/* Updated Home.css */
.startPage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%);
  text-align: center;
}

.startPage-container h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.home-button {
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.home-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.home-button:hover::before {
  opacity: 1;
}

.home-button-login {
  background-color: #4887ec;
  color: white;
}

.home-button-signup {
  background-color: #ff7043;
  color: white;
}

.home-button-login:hover, .home-button-signup:hover {
  transform: translateY(-3px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.home-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 135, 236, 0.5);
}

.home-button-signup:focus {
  box-shadow: 0 0 0 3px rgba(255, 112, 67, 0.5);
}

@media screen and (max-width: 768px) {
  .startPage-container h2 {
      font-size: 2rem;
  }

  .home-button {
      padding: 12px 25px;
      font-size: 0.9rem;
  }
}
