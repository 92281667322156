/* Updated NavigationBar.css */
.navbar {
  background: #ffffff;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.navbar-logo {
  color: #333;
  font-weight: 700;
  font-size: 1.8rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-logo:hover {
  color: #4887ec;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-item {
  margin: 0 10px;
}

.nav-links {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  position: relative;
  transition: all 0.3s ease;
}

.nav-links::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #4887ec;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.nav-links:hover::after {
  width: 100%;
}

@media screen and (max-width: 960px) {
  .navbar-container {
      flex-direction: column;
  }
  .nav-menu {
      flex-direction: column;
      width: 100%;
      display: none;
  }
  .nav-item {
      width: 100%;
      text-align: center;
      margin: 0;
  }
  .nav-links {
      width: 100%;
      padding: 15px;
  }
  .nav-links::after {
      left: 0;
      transform: translateX(0);
  }
  .nav-menu.active {
      display: flex;
  }
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #333;
  }
}
