.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
    background: linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%);
    font-family: Arial, sans-serif;
  }
  
  .not-found-container h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .not-found-link {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 25px;
    background-color: #4887ec;
    color: white;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #366fc2;
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  