@import url('https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;700&display=swap');

body, .blog-list, .blog-card, .author-info, .tags, .tag, button, #output h1, #output h2, #output h3, #output p, #output ul, #output li {
  font-family: 'Proxima Nova', sans-serif;
}
.blog-list-container {
  display:flex;
  justify-content:center;
  align-items: center;
  
}
.blog-list {
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.blog-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  background-color: #fff;
}

.author-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.author-info img {
  border-radius: 50%;
  margin-right: 8px;
}

.tags {
  margin: 16px 0;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
}

button {
  background-color: #4196f1;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #064283;
}


#output h1, #output h2, #output h3 {
  color: #333;
}

#output h1 {
  font-size: 1.5em;
}

#output h2 {
  font-size: 1.5em;
}

#output h3 {
  font-size: 1.25em;
}

#output p {
  margin: 10px 0;
}

#output ul {
  margin: 10px 0;
  padding-left: 20px;
}

#output li {
  margin: 5px 0;
}

.blog-card br {
  display: none;      
}