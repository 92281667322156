#output h1, #output h2, #output h3 {
    color: #333;
}

#output h1 {
    font-size: 2em;
}

#output h2 {
    font-size: 1.5em;
}

#output h3 {
    font-size: 1.25em;
}

#output p {
    margin: 10px 0;
}

#output ul {
    margin: 10px 0;
    padding-left: 20px;
}

#output li {
    margin: 5px 0;
}

