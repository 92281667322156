/* BlogsGenerator.css */
.blog-gen-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%);
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.blog-gen-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .blog-gen-content {
    flex-direction: column;
    align-items: center;
  }
}

.blog-gen-form-container {
  display: flex;
  flex-direction: column;
  margin-right: 60px;  /* Increased margin for more space */
  width: 100%;
  max-width: 400px;
}

@media (max-width: 768px) {
  .blog-gen-form-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.blog-gen-button-add {
  margin-bottom: 20px;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #4887ec;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.blog-gen-button-add:hover {
  background-color: #366fc2;
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.blog-gen-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.blog-gen-form label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
}

.blog-gen-form input,
.blog-gen-form select {
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.blog-gen-form input:focus,
.blog-gen-form select:focus {
  border-color: #4887ec;
  outline: none;
  box-shadow: 0 0 5px rgba(72, 135, 236, 0.5);
}

.blog-gen-button-generate {
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #4887ec;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-gen-button-generate:hover {
  background-color: #366fc2;
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.blog-gen-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blog-gen-output-container {
  flex: 1;
  width: 100%;
}

.blog-gen-output {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;  /* Added margin-top for spacing */
}

.blog-gen-error-message {
  color: red;
  margin-top: 10px;
}

.blog-gen-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.blog-gen-button-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #4887ec;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.blog-gen-button-copy:hover {
  background-color: #366fc2;
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
