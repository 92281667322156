.container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    margin: 20px;
    box-sizing: border-box;
}
  
  
#searchField, input[type="text"], textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}
  
.blog-list {
    margin: 20px 0;
}
  
.blog-list div {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.blog-list div:hover {
    background-color: #f1f1f1;
}
  
label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}
  
textarea {
    height: 100px;
}
  
.searchResult {
    background-color: #fcfcfc;
    padding: 15px;
    border: 1px solid #0d0101;
    border-radius: 8px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .searchResult h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .searchResult p {
    margin: 5px 0 0;
    font-size: 1em;
    color: hwb(207 54% 7%);
  }
  
  .searchResult:hover {
    background-color: #b4daf1;
    transform: scale(1.02);
  }
  